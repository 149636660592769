/* eslint-disable no-restricted-globals */
import { useState, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import { object } from 'prop-types';
import { Button } from '../../../components/common/button';
import { Wrap } from '../../../components/sections-wrap';
import { Spinner } from '../../../components/spinner';
import { ResetButton } from '../../../components/common/reset-button';
import { SectionTitle } from '../../../components/alimony-calculator/question/section-title';
import { Strategy } from '../../../components/alimony-calculator/strategy';
import { SendResults } from '../../../components/alimony-calculator/send-results';
import { Header, Checkbox } from '../../../components/alimony-calculator';
import {
  saveStateInHistory, removeItemLocalStorage, getLocalStorageItem,
} from '../../../helpers';
import {
  coloradoRedirectToCalculatorIfNecessary,
  coloradoRedirectToTermsAndConditionIfNecessary,
} from '../../../functions-calculator';
import { constants } from '../../../config';
import { ZoomCalendar } from '../../../components/zoom-calendar';

import * as styles from './index.module.scss';

function AlimonyStrategies({ data, location }) {
  const [open, setOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [strategies, setStrategies] = useState([]);
  const [strategyTypeDesired, setStrategiesTypeDesired] = useState('minimize');
  const [strategiesSelected, setStrategiesSelected] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { pageSettings, allContentfulCalculatorStrategy } = data;
  const { uri, calculatorType: calculatorTypes, calculatorTypeDefault } = pageSettings;
  const submit = () => {
    const newState = { ...window.history.state, ...{ strategiesSelected, isSubmitted } };
    saveStateInHistory(newState);
    navigate(`/colorado/alimony-strategies/?type=${strategyTypeDesired}`);
    setIsSubmitted(true);
  };

  // TODO: Update it when we know which calculator the user has ended (CARD: RH-683)
  const handleStartOver = () => {
    removeItemLocalStorage(
      constants.calculatorsIds.coloradoAlimonyCalculator.calculatorFinishedKey,
    );
    calculatorTypes.map(
      (item) => removeItemLocalStorage(item.name),
    );
    let currentCalculatorType = calculatorTypes.find((item) => item.name === getLocalStorageItem('currentCalculator'))?.type;
    currentCalculatorType = currentCalculatorType || calculatorTypeDefault.type;
    navigate(`${uri}/${currentCalculatorType}`);
  };

  useEffect(() => {
    const wasRedirectedToTermsAndCondition = coloradoRedirectToTermsAndConditionIfNecessary(
      navigate,
    );
    let wasRedirectedToCalculator = false;
    if (!wasRedirectedToTermsAndCondition) {
      wasRedirectedToCalculator = coloradoRedirectToCalculatorIfNecessary(
        uri,
        calculatorTypes,
        calculatorTypeDefault.type,
        navigate,
      );
      if (!wasRedirectedToCalculator) {
        const { search } = location ?? {};
        const queryString = search ?? '';
        const urlParams = new URLSearchParams(queryString);
        const strategyType = urlParams.get('type') || '';
        setStrategiesTypeDesired(strategyType);
        const allStrategies = allContentfulCalculatorStrategy?.nodes ?? [];
        const options = strategyType
          ? allStrategies.filter(({ type }) => type === strategyType).map(({ option }) => option)
          : [];
        setStrategies(options);
        setIsLoaded(false);
      }
    }
  }, []);

  useEffect(() => {
    const { state } = history;
    const isSubmittedSaved = state?.isSubmitted === false || state?.isSubmitted === true;
    if (isSubmittedSaved) {
      setIsSubmitted(history.state.isSubmitted ?? false);
      setStrategiesSelected(history.state.strategiesSelected ?? []);
    }
  }, [location.state]);

  useEffect(() => {
    const { state } = history;
    const isSubmittedSaved = state?.isSubmitted === false || state?.isSubmitted === true;
    if (!isSubmittedSaved) {
      const newState = { ...window.history.state, ...{ isSubmitted, strategiesSelected } };
      saveStateInHistory(newState);
    }
  }, [location.state]);

  // Define a function to be executed when the user navigates back
  const onNavigateByArrows = () => {
    const { state } = history;
    const isSubmittedSaved = state?.isSubmitted === false || state?.isSubmitted === true;
    if (isSubmittedSaved) {
      setIsSubmitted(history.state.isSubmitted);
      setStrategiesSelected(history.state.strategiesSelected ?? []);
    }
  };

  //  Attach an event listener to the onpopstate event (onClick on browser arrows)
  if (typeof window !== 'undefined') {
    window.onpopstate = (event) => {
      if (event.state !== null) {
        onNavigateByArrows();
      }
    };
  }

  useEffect(() => {
    if (isSubmitted) {
      const allStrategies = allContentfulCalculatorStrategy?.nodes ?? [];
      if (!!strategiesSelected.length && typeof strategiesSelected[0] === 'string') {
        const strategiesSelectedAllData = allStrategies.filter(
          ({ type, option }) => type === strategyTypeDesired && strategiesSelected.includes(option),
        );
        setStrategiesSelected(strategiesSelectedAllData);
      }
    }
  }, [isSubmitted]);
  return (
    <>
      {open && (
      <ZoomCalendar onClose={() => setOpen(false)} />
      )}
      <div className={styles.pageStyles}>
        <Wrap>
          {isLoaded ? (
            <div className={styles.spinnerContainer}>
              <Spinner className={styles.spinner} />
            </div>
          ) : (
            <div className={styles.calculatorContainer}>
              <Header />
              {isSubmitted ? (
                <>
                  <SectionTitle
                    sectionName={`Strategies to ${strategyTypeDesired === 'minimize' ? 'Reduce' : 'Increase'} your Alimony Payments`}
                  />
                  {strategiesSelected.map(({ title, strategy }, index) => (
                    <Strategy
                      key={title}
                      title={title}
                      strategy={strategy?.strategy}
                      showLine={index !== strategiesSelected.length - 1}
                    />
                  ))}
                  <div className={styles.scheduleButton}>
                    <Button
                      text="Schedule now"
                      buttonStyle="primary"
                      className={styles.buttonStyle}
                      onClick={() => setOpen(true)}
                    />
                  </div>
                  <SendResults calculatorId={constants.calculatorsIds.coloradoAlimonyCalculator.id} />
                </>
              ) : (
                <div className={styles.strategiesContainer}>
                  <SectionTitle sectionName="Begin your alimony strategy" />
                  <div className={styles.contentContainer}>
                    <Checkbox
                    options={strategies}
                    value={strategiesSelected}
                    onChange={setStrategiesSelected}
                  />
                    <div className={styles.buttonsContainer}>
                    <div className={styles.buttonContainer}>
                      <Button
                        text="Submit"
                        buttonStyle="primaryAlimony"
                        className={styles.button}
                        onClick={submit}
                        disabled={!strategiesSelected.length}
                      />
                    </div>
                    <ResetButton handleAccept={handleStartOver} />
                  </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Wrap>
      </div>
    </>
  );
}

export const query = graphql`
  {
    wpPage: contentfulCalculatorType(contentful_id: {eq: "26LdWvuKhdEOqTdxEj2rOe"}) {
      titleSchema
      descriptionSchema
    }
    pageSettings: contentfulCalculators(id: {eq: "a6fd1acd-aa90-5d5f-88c1-67d30aff00c1"}) {
      uri
      name
      contentfulid
      calculatorType {
        type
        name
      }
      calculatorTypeDefault {
        type
        name
      }
    }
    allContentfulCalculatorStrategy {
      nodes {
        title
        type
        option
        strategy {
          strategy
          id
        }
      }
    }
  }
`;

AlimonyStrategies.propTypes = {
  location: object,
  data: object,
};

AlimonyStrategies.defaultProps = {
  location: {},
  data: {},
};

export default AlimonyStrategies;
export { Head } from '../../../components/seo/seo';
