// extracted by mini-css-extract-plugin
export var button = "index-module--button--f6121";
export var buttonContainer = "index-module--button-container--1a033";
export var buttonsContainer = "index-module--buttons-container--91f65";
export var calculatorContainer = "index-module--calculator-container--02656";
export var contentContainer = "index-module--content-container--087b4";
export var pageStyles = "index-module--page-styles--184f0";
export var popup = "index-module--popup--21f93";
export var scheduleButton = "index-module--schedule-button--889b5";
export var spinner = "index-module--spinner--1a053";
export var spinnerContainer = "index-module--spinner-container--be2b1";
export var strategiesContainer = "index-module--strategies-container--50e3c";