import { bool, string } from 'prop-types';
import * as styles from './index.module.scss';

function Strategy({ title, strategy, showLine }) {
  return (
    <div className={styles.strategyContainer}>
      <h2 className={styles.title}>
        <b>{title}</b>
      </h2>
      <span className={styles.strategyText}>
        {strategy}
      </span>
      {showLine && (
        <div>
          <hr />
        </div>
      )}
    </div>
  );
}

Strategy.propTypes = {
  title: string,
  strategy: string,
  showLine: bool,
};

Strategy.defaultProps = {
  title: '',
  strategy: '',
  showLine: true,
};

export { Strategy };
